import React, { useEffect, useRef } from 'react'
import mapboxgl from 'mapbox-gl'

import './Map.css'

const createAndAddPopup = (event, client, map) => {
  const coordinates = event.features[0].geometry.coordinates.slice()
  const substation = event.features[0].properties.cn_substat
  const feeder = event.features[0].properties.cn_feeder
  const sequence = event.features[0].properties.sequence.toString()

  new mapboxgl.Popup()
    .setLngLat(coordinates)
    .setHTML(
      `<p>${client}/${substation}/${feeder}/${sequence}</p><p><a href="${
        process.env.PUBLIC_URL
      }/splice-diagram/${client}/${substation}/${feeder}/${sequence}">View the splice diagram</a></p>`
    )
    .addTo(map)
}

const Map = ({ match }) => {
  const mapDiv = useRef(null)

  useEffect(() => {
    mapboxgl.accessToken =
      'pk.eyJ1IjoiY29uZXhvbi1kZXNpZ24iLCJhIjoiY2pvdzZlb2djMXVhOTN3bmhpYzk3NndoZCJ9.On4IrAd0sgmmgd_sAqg_Gg'

    const map = new mapboxgl.Map({
      container: mapDiv.current,
      //hash: true,
      style: 'mapbox://styles/conexon-design/cjow6vt3sax7q2rpbj5wm7s84',
      zoom: 10,
      center: [-95.59328823242362, 35.50842745840036]
    })

    map.on('load', () => {
      // load geojson sources
      map.addSource('cable', {
        type: 'geojson',
        data: `${process.env.PUBLIC_URL}/${
          match.params.client
        }/geojson/cable.geojson`
      })

      map.addSource('consumers', {
        type: 'geojson',
        data: `${process.env.PUBLIC_URL}/${
          match.params.client
        }/geojson/consumers.geojson`
      })

      map.addSource('poles', {
        type: 'geojson',
        data: `${process.env.PUBLIC_URL}/${
          match.params.client
        }/geojson/poles.geojson`
      })

      map.addSource('splicepoint', {
        type: 'geojson',
        data: `${process.env.PUBLIC_URL}/${
          match.params.client
        }/geojson/splicepoint.geojson`
      })

      // cable and counts (text)
      map.addLayer({
        id: 'cable',
        type: 'line',
        source: 'cable',
        layout: { 'line-join': 'round', 'line-cap': 'round' },
        paint: {
          'line-width': {
            property: 'cablesize',
            stops: [
              [{ value: 4, zoom: 13 }, 1],
              [{ value: 12, zoom: 13 }, 1.25],
              [{ value: 24, zoom: 13 }, 1.5],
              [{ value: 48, zoom: 13 }, 1.75],
              [{ value: 96, zoom: 13 }, 2]
            ]
          },
          'line-color': {
            property: 'class',
            type: 'categorical',
            stops: [['A', 'blue'], ['U', 'brown']]
          }
        }
      })

      // cable-strand count (text)
      map.addLayer({
        id: 'cableStrandCounts',
        type: 'symbol',
        source: 'cable',
        paint: {
          'text-color': 'black'
        },
        layout: {
          'text-field': '{cablesize}',
          'symbol-placement': 'line',
          'text-allow-overlap': true,
          'text-justify': 'center',
          'text-rotation-alignment': 'viewport',
          'text-size': {
            base: 1,
            stops: [[11, 0], [12, 8], [13, 9], [14, 10], [15, 12], [16, 14]]
          }
        }
      })

      // poles
      map.addLayer({
        id: 'poles',
        type: 'circle',
        source: 'poles',
        paint: {
          'circle-color': 'black',
          'circle-radius': {
            stops: [[12, 1], [13, 2], [14, 3], [15, 4], [16, 5]]
          }
        }
      })

      // consumers
      map.addLayer({
        id: 'consumers',
        type: 'circle',
        source: 'consumers',
        paint: {
          'circle-color': 'white',
          'circle-stroke-color': 'orange',
          'circle-stroke-width': 1,
          'circle-radius': {
            stops: [[12, 1], [13, 3], [14, 5], [15, 8], [16, 10]]
          }
        }
      })

      // consumers "M" (M = meter)
      map.addLayer({
        id: 'consumersM',
        type: 'symbol',
        source: 'consumers',
        paint: {
          'text-color': 'orange'
        },
        layout: {
          'text-field': 'M',
          'symbol-placement': 'point',
          'text-allow-overlap': true,
          'text-justify': 'center',
          'text-padding': 4,
          'text-rotation-alignment': 'viewport',
          'text-size': {
            stops: [[13, 0], [14, 6], [15, 12], [16, 14]]
          }
        }
      })

      map.addLayer({
        id: 'spliceCans',
        type: 'symbol',
        source: 'splicepoint',
        filter: ['all', ['==', 'subtype', 1]],
        layout: {
          'icon-image': 'blue-bowtie-15',
          'icon-allow-overlap': true,
          'icon-size': {
            stops: [[12, 0.25], [13, 0.5], [14, 0.75], [15, 1]]
          }
        }
      })

      map.addLayer({
        id: 'substations',
        type: 'symbol',
        source: 'splicepoint',
        filter: ['all', ['==', 'subtype', 4]],
        layout: {
          'icon-image': 'black-square-15',
          'icon-allow-overlap': true,
          'icon-size': {
            base: 0.5,
            stops: [[12, 0.5], [13, 0.75], [14, 1], [15, 1.25], [16, 1.5]]
          }
        }
      })

      map.addLayer({
        id: 'taps',
        type: 'symbol',
        source: 'splicepoint',
        filter: ['all', ['==', 'subtype', 13]],
        // removes null
        // filter: ['==', ['typeof', ['get', 'tube']], 'string'],
        layout: {
          'icon-allow-overlap': true,
          'icon-image': [
            // get the number of tap ports to use the correct image
            // eg blue-circle-15 or rose-octagon-15
            'step',
            ['to-number', ['get', 'tapport']],
            '',
            2,
            [
              'concat',
              // get the tubes color to use the correct image
              // eg blue-circle-15 or rose-octagon-15
              ['downcase', ['to-string', ['get', 'tube']]],
              '-circle-15'
            ],
            4,
            [
              'concat',
              ['downcase', ['to-string', ['get', 'tube']]],
              '-square-15'
            ],
            8,
            [
              'concat',
              ['downcase', ['to-string', ['get', 'tube']]],
              '-octagon-15'
            ]
          ],
          'icon-size': {
            stops: [[12, 0.5], [13, 1], [14, 1.25], [15, 1.75], [16, 2]]
          }
        }
      })

      map.addLayer({
        id: 'strands',
        type: 'circle',
        source: 'splicepoint',
        filter: ['all', ['==', 'subtype', 13]],
        // filter to avoid null values
        // filter: ['==', ['typeof', ['get', 'strand']], 'string'],
        paint: {
          'circle-color': [
            // most colors translate to HTML named colors
            // slate and rose do not, so we convert them
            'case',
            ['==', ['to-string', ['get', 'strand']], 'slate'],
            'slategray',
            ['==', ['to-string', ['get', 'strand']], 'rose'],
            'mistyrose',
            ['get', ['downcase', 'strand']]
          ],
          'circle-radius': {
            stops: [[12, 2], [13, 4], [14, 6], [15, 7], [16, 10]]
          }
        }
      })

      /*
      shows IDs for the solid black circles
      
      map.addLayer({
        id: 'testLabel',
        type: 'symbol',
        source: 'splicepoint',
        filter: ['all', ['==', 'subtype', 13]],
        paint: {
          'text-color': 'purple'
        },
        layout: {
          'text-field': '{stationid}',
          'symbol-placement': 'point',
          'text-allow-overlap': true,
          'text-justify': 'center',
          'text-padding': 4,
          'text-rotation-alignment': 'viewport',
          'text-size': {
            stops: [[13, 0], [14, 6], [15, 12], [16, 14]]
          }
        }
      })
      */

      map.addLayer({
        id: 'tapLabel',
        type: 'symbol',
        source: 'splicepoint',
        filter: ['all', ['==', 'subtype', 13]],
        paint: {
          'text-color': [
            'case',
            ['==', ['get', 'strand'], 'slate'],
            'white',
            ['==', ['get', 'strand'], 'green'],
            'white',
            ['==', ['get', 'strand'], 'brown'],
            'white',
            ['==', ['get', 'strand'], 'black'],
            'white',
            ['==', ['get', 'strand'], 'blue'],
            'white',
            'black'
          ]
        },
        layout: {
          'text-field': '{loss}',
          'symbol-placement': 'point',
          'text-allow-overlap': true,
          'text-justify': 'center',
          'text-padding': 4,
          'text-rotation-alignment': 'viewport',
          'text-size': {
            stops: [[13, 0], [14, 6], [15, 12], [16, 14]]
          }
        }
      })

      map.on('click', 'spliceCans', event => {
        createAndAddPopup(event, match.params.client, map)
      })

      map.on('click', 'taps', event => {
        createAndAddPopup(event, match.params.client, map)
      })

      /*
      map.on('drag', () => {
        //console.log(map.getCenter())
        //console.log(map.getZoom())
        //console.log(map.queryRenderedFeatures({ layers: ['substations'] }))
      })
      //*/

      map.addControl(new mapboxgl.NavigationControl())
    })

    // remove the map when component unmounts
    return function cleanUp() {
      map.remove()
    }
  })

  return (
    <div className="map-wrapper">
      <div id="map" ref={mapDiv} className="mapFull" />
    </div>
  )
}

export default Map
