import React from 'react'

const Home = () => {
  return (
    <React.Fragment>
      <h1>Home</h1>
      <p>Some content about what this site does.</p>
    </React.Fragment>
  )
}

export default Home
