import React from 'react'
import useForm from './useForm'

import './ConfigForm.css'

const initialState = {
  primaryLineCodeList: [1, 3],
  secondaryLineCodeList: '(2.4)',
  aerialCodes: '(1,2)',
  newSpanDist: 50,
  cableclassCutOff: 48,
  custLimit: 8,
  forkLimit: 1200,
  forkLimits: [
    [1, 1200],
    [2, 800],
    [3, 400],
    [4, 400],
    [5, 200],
    [6, 200],
    [7, 200],
    [8, 200]
  ],
  consolidateDict: { 1: 1000, 2: 800, 4: 600, 8: 200 },
  consolidateDist: 800,
  ADSSconsolidateDist: 800,
  maxPort: 24,
  startLT: 0.0,
  minLT: -23,
  ltLossKM: 0.38,
  ltLoss: 'typical',
  tapLoss: 0.25,
  spliceLoss: 0.25,
  ftToMtr: 0.0003048,
  tapRunTime: 'ordinal',
  cableSizes: [12, 24, 48, 96, 144, 288],
  cableBumpDict: { 12: 0.5, 24: 0.5, 48: 0.6, 96: 0.75, 144: 0.8, 288: 0 },
  maxPoleDist: 50,
  longFiberDistance: 65000,
  extraCableAtTap: 40
}

const Form = props => {
  const { values, handleChange, handleSubmit } = useForm(login, initialState)

  const { client, substation, feeder } = props.match.params

  function login() {
    props.history.push(
      `/${client}/auto-design/${substation}/${feeder}/process`,
      { substationName: props.location.state.substationName }
    )
  }

  return (
    <div className="ConfigForm">
      <div>
        <h2>
          Configuration for {client.toUpperCase()}{' '}
          {props.location.state.substationName} - {feeder}
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="alert">
            <p>
              The default configuration listed below is used in almost all
              cases.
            </p>
            <p>
              If this is the case, go ahead and{' '}
              <button className="inline small" type="submit">
                click here to run Auto-design
              </button>
              .
            </p>
          </div>
          {Object.keys(initialState).map(key => {
            return (
              <React.Fragment key={key}>
                <label>{key}</label>
                <input
                  name={key}
                  id={key}
                  onChange={handleChange}
                  value={values[key]}
                  required
                />
              </React.Fragment>
            )
          })}

          <button type="submit">Run Auto-design</button>
        </form>
      </div>
    </div>
  )
}

export default Form
