import React from 'react'

import './Chart.css'

const Chart = ({ data }) => {
  const percent = Math.floor((data.actual / data.planned) * 100)
  const color = percent >= 100 ? 'success' : percent >= 75 ? 'warn' : 'fail'

  return <p className={`Chart ${color}`}>{percent}%</p>
}

export default Chart
