import auth0 from 'auth0-js'
import { AUTH_CONFIG } from './config'

export default class Auth {
  accessToken
  idToken
  expiresAt
  userProfile
  tokenRenewalTimeout

  auth0 = new auth0.WebAuth({
    domain: AUTH_CONFIG.domain,
    clientID: AUTH_CONFIG.clientId,
    redirectUri: AUTH_CONFIG.callbackUrl,
    responseType: 'token id_token',
    scope: 'openid profile'
  })

  constructor() {
    this.login = this.login.bind(this)
    this.logout = this.logout.bind(this)
    this.handleAuthentication = this.handleAuthentication.bind(this)
    this.isAuthenticated = this.isAuthenticated.bind(this)
    this.getAccessToken = this.getAccessToken.bind(this)
    this.getIdToken = this.getIdToken.bind(this)
    this.renewSession = this.renewSession.bind(this)
    this.getProfile = this.getProfile.bind(this)
    this.scheduleRenewal()
  }

  login() {
    this.auth0.authorize()
  }

  handleAuthentication() {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err, authResult) => {
        if (err) return reject(err)

        if (!authResult || !authResult.idToken) {
          return reject(err)
        }
        this.setSession(authResult)
        resolve()
      })
    })
  }

  renewSessionPromise() {
    return new Promise((resolve, reject) => {
      this.auth0.checkSession({}, (err, authResult) => {
        if (err) {
          this.logout()
          console.log(err)
          alert(
            `Could not get a new token (${err.error}: ${
              err.error_description
            }).`
          )
          return reject(err)
        }

        if (!authResult || !authResult.idToken) {
          this.logout()
          console.log(err)
          alert(
            `Could not get a new token (${err.error}: ${
              err.error_description
            }).`
          )
          return reject(err)
        }
        this.setSession(authResult)
        resolve()
      })
    })
  }

  scheduleRenewal() {
    let expiresAt = this.expiresAt
    const timeout = expiresAt - Date.now()
    if (timeout > 0) {
      this.tokenRenewalTimeout = setTimeout(() => {
        this.renewSession()
      }, timeout)
    }
  }

  getExpiryDate() {
    return JSON.stringify(new Date(this.expiresAt))
  }

  getAccessToken() {
    return this.accessToken
  }

  getIdToken() {
    return this.idToken
  }

  setSession(authResult) {
    // Set isLoggedIn flag in localStorage
    localStorage.setItem('isLoggedIn', 'true')

    // Set the time that the access token will expire at
    let expiresAt = authResult.expiresIn * 1000 + new Date().getTime()
    this.accessToken = authResult.accessToken
    this.idToken = authResult.idToken
    this.profile = authResult.idTokenPayload
    this.expiresAt = expiresAt
  }

  renewSession() {
    this.auth0.checkSession({}, (err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult)
      } else if (err) {
        this.logout()
        console.log(err)
        alert(
          `Could not get a new token (${err.error}: ${err.error_description}).`
        )
      }
    })
  }

  getProfile() {
    return this.profile
  }

  logout() {
    // Remove tokens and expiry time
    this.accessToken = null
    this.idToken = null
    this.expiresAt = 0

    // Remove user profile
    this.userProfile = null

    // Clear token renewal
    clearTimeout(this.tokenRenewalTimeout)

    // Remove isLoggedIn flag from localStorage
    localStorage.removeItem('isLoggedIn')

    this.auth0.logout({
      returnTo: window.location.origin
    })
  }

  isAuthenticated() {
    // Check whether the current time is past the
    // access token's expiry time
    let expiresAt = this.expiresAt
    return new Date().getTime() < expiresAt
  }
}
