import React from 'react'
import { Link, withRouter } from 'react-router-dom'

import './Summary.css'

const Summary = props => {
  return (
    <div className="Summary">
      <header>
        <h3>Summary</h3>
        <p>Week ending 1/11/2019</p>
        <p>
          Some content to describe what we'll see here. Lorem ipsum, or lipsum
          as it is sometimes known, is dummy text used in laying out print,
          graphic or web designs. The passage is attributed to an unknown
          typesetter in the 15th century.
        </p>
      </header>

      <div className="wrapper">
        {props.substations.map((substation, i) => {
          return (
            <div key={i}>
              <h2>{substation.name}</h2>
              <p>Some high level info about each substation.</p>
              <Link to={`${props.match.url}/${substation.id}`}>
                View details
              </Link>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default withRouter(Summary)
