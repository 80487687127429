import React from 'react'
import { Link } from 'react-router-dom'

import './SubstationFeeders.css'

const clients = [
  {
    name: 'ECOEC',
    substations: [
      {
        id: 4,
        name: 'Pierce',
        feeders: [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }]
      },
      {
        id: 23,
        name: 'Texanna',
        feeders: [{ id: 1 }, { id: 2 }, { id: 3 }]
      },
      {
        id: 13,
        name: 'Onapa',
        feeders: [{ id: 1 }, { id: 2 }, { id: 3 }]
      },
      {
        id: 11,
        name: 'Summit',
        feeders: [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }]
      },
      {
        id: 6,
        name: 'Eufaula',
        feeders: [{ id: 1 }]
      }
    ]
  },
  {
    name: 'Test',
    substations: [
      { id: 3, feeders: [{ id: 3 }, { id: 4 }] },
      { id: 2, feeders: [{ id: 3 }, { id: 4 }] }
    ]
  }
]

const SubstationFeeders = ({ match }) => {
  // get the current client
  const client = clients.filter(theOne => {
    if (theOne.name.toLowerCase() === match.params.client) {
      return true
    }

    return false
  })

  return (
    <div className="SubstationFeeders">
      <div>
        <h2>Select a substation/feeder</h2>

        {client.map((client, i) => {
          return (
            <React.Fragment key={i}>
              {client.substations.map((substation, i) => {
                return (
                  <React.Fragment key={i}>
                    <h3>
                      {substation.id} - {substation.name}
                    </h3>
                    <ul>
                      {substation.feeders.map((feeder, i) => {
                        return (
                          <li key={i}>
                            <Link
                              to={{
                                pathname: `${match.url}/${substation.id}/${
                                  feeder.id
                                }/config`,
                                state: { substationName: substation.name }
                              }}
                            >
                              {substation.name} {feeder.id}
                            </Link>
                          </li>
                        )
                      })}
                    </ul>
                  </React.Fragment>
                )
              })}
            </React.Fragment>
          )
        })}
      </div>
    </div>
  )
}

export default SubstationFeeders
