import React from 'react'

import Stat from './Stat'

import './Stats.css'

const Stats = props => {
  if (props.category === 'Consumers') return null

  const categoryClass = props.category.replace(' ', '-').toLowerCase()

  return (
    <div className={`Stats ${categoryClass}`}>
      <h3>
        {props.count + 1}. {props.category}
      </h3>
      <div className="wrapper">
        {props.stats.map((stat, i) => {
          return <Stat key={i} stat={stat} />
        })}
      </div>
      {props.category === 'Make ready' ? (
        <p>Delays due to bad weather.</p>
      ) : null}
    </div>
  )
}

export default Stats
