import React, { useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import mapboxgl from 'mapbox-gl'

import Chart from './Chart'
import './Map.css'

const Map = ({ client }) => {
  const mapDiv = useRef(null)

  useEffect(() => {
    mapboxgl.accessToken =
      'pk.eyJ1IjoiY29uZXhvbi1kZXNpZ24iLCJhIjoiY2pvdzZlb2djMXVhOTN3bmhpYzk3NndoZCJ9.On4IrAd0sgmmgd_sAqg_Gg'

    const map = new mapboxgl.Map({
      container: mapDiv.current,
      //hash: true,
      style: 'mapbox://styles/conexon-design/cjow6vt3sax7q2rpbj5wm7s84',
      zoom: 9.5,
      center: [-95.56634417983116, 35.49279686927508]
    })

    map.on('load', () => {
      map.addSource('cable', {
        type: 'geojson',
        data: `${process.env.PUBLIC_URL}/${client}/geojson/cable.geojson`
      })

      map.addLayer({
        id: 'cable',
        type: 'line',
        source: 'cable',
        layout: { 'line-join': 'round', 'line-cap': 'round' },
        paint: {
          'line-width': 1.5,
          'line-color': 'hsla(0, 0%, 0%, 0.15)'
        }
      })

      fetch(`${process.env.PUBLIC_URL}/${client}/geojson/splicepoint.geojson`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
        .then(response => {
          return response.json()
        })
        .then(json => {
          // get unique
          let keys = {}
          const uniqueSubstations = json.features.filter(feature => {
            if (feature.properties.subtype === 4) {
              if (keys[feature.geometry.coordinates.toString()]) {
                return false
              } else {
                keys[feature.geometry.coordinates.toString()] = true
                return true
              }
            }

            return false
          })

          // render unique
          uniqueSubstations.forEach(substation => {
            let element = document.createElement('div')
            ReactDOM.render(
              <Chart
                data={{
                  planned: Math.floor(Math.random() * 100 + 1),
                  actual: Math.floor(Math.random() * 100 + 1)
                }}
              />,
              element
            )
            new mapboxgl.Marker({ element: element })
              .setLngLat(substation.geometry.coordinates)
              .addTo(map)
          })
        })
    })

    /*
    map.on('drag', () => {
      console.log(map.getCenter())
      console.log(map.getZoom())
      //console.log(map.queryRenderedFeatures({ layers: ['substations'] }))
    })
    //*/

    // remove the map when component unmounts
    return function cleanUp() {
      map.remove()
    }
  })

  return (
    <div className="MapProgress">
      <h4>Showing % miles complete for each substation.</h4>
      <div className="map-wrapper">
        <div id="map" ref={mapDiv} />
      </div>
    </div>
  )
}

export default Map
