export const fiberColors = [
  { id: 1, color: 'blue' },
  { id: 2, color: 'orange' },
  { id: 3, color: 'green' },
  { id: 4, color: 'brown' },
  { id: 5, color: 'slategray' },
  { id: 6, color: 'white' },
  { id: 7, color: 'red' },
  { id: 8, color: 'black' },
  { id: 9, color: 'yellow' },
  { id: 10, color: 'violet' },
  { id: 11, color: 'mistyrose' },
  { id: 12, color: 'aqua' }
]
