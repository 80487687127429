import { fiberColors } from '../constants/fiberColors'

export const getStrandColor = (strandId, cableSize) => {
  // in 4 strand cables the 4th tube is aqua, instead of brown
  if (strandId === 4 && cableSize === 4) return 'aqua'

  const id = strandId % 12 === 0 ? 12 : strandId % 12

  return fiberColors.find(color => color.id === id).color
}
