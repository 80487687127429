import React from 'react'
import { Link } from 'react-router-dom'

import './ClientList.css'

// TODO: this data should come from an API
const clients = [
  {
    id: 'ECOEC',
    name: 'East Central Electric Cooperative',
    city: 'Okmulgee',
    state: 'OK'
  },
  {
    id: 'MTEC',
    name: 'My Test Electric Cooperative',
    city: 'Fayetteville',
    state: 'PA'
  }
]

const ClientList = ({ match }) => {
  return (
    <div className="ClientList">
      <h1>Select a client to get started</h1>
      <ul>
        {clients.map((client, i) => {
          return (
            <li key={i}>
              <h3>
                <Link to={`/${client.id.toLowerCase()}`}>
                  {client.name} ({client.id})
                </Link>
              </h3>
              <p>
                {client.city}, {client.state}
              </p>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default ClientList
