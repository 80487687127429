import React from 'react'
import { Route, NavLink } from 'react-router-dom'

import Dashboard from '../Dashboard/Dashboard'
import Subscribers from '../Subscribers/Subscribers'
import WorkOrders from '../WorkOrders/WorkOrders'
import Substations from '../Substations/Substations'
import MapFull from '../common/MapFull'
import AutoDesign from '../AutoDesign/AutoDesign'

import './Clients.css'

const Clients = props => {
  const profile = props.auth.getProfile()

  return (
    <div className="Clients">
      <h1 className="client">{props.match.params.client}</h1>

      <nav className="Nav">
        <ul>
          <li>
            <NavLink exact to={`${props.match.url}`} activeClassName="active">
              Dashboard
            </NavLink>
          </li>
          {/*
          <li>
            <NavLink
              to={`${props.match.url}/subscribers`}
              activeClassName="active"
            >
              Subscribers
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`${props.match.url}/work-orders`}
              activeClassName="active"
            >
              Work Orders
            </NavLink>
          </li>
          */}
          <li>
            <NavLink
              to={`${props.match.url}/substations`}
              activeClassName="active"
            >
              Substations
            </NavLink>
          </li>
          <li>
            <NavLink to={`${props.match.url}/map`} activeClassName="active">
              Map
            </NavLink>
          </li>
          {profile['https://conexon.com/roles'].includes('admin') ? (
            <React.Fragment>
              <li>
                <NavLink
                  to={`${props.match.url}/auto-design`}
                  activeClassName="active"
                >
                  Auto-design
                </NavLink>
              </li>
            </React.Fragment>
          ) : null}
        </ul>
      </nav>

      <Route path={`${props.match.path}/subscribers`} component={Subscribers} />
      <Route path={`${props.match.path}/work-orders`} component={WorkOrders} />
      <Route path={`${props.match.path}/substations`} component={Substations} />
      <Route path={`${props.match.path}/map`} component={MapFull} />
      <Route path={`${props.match.path}/auto-design`} component={AutoDesign} />
      <Route exact path={props.match.path} component={Dashboard} />
    </div>
  )
}

export default Clients
