import React from 'react'
import { Route, Switch } from 'react-router-dom'

import SubstationFeeders from './SubstationFeeders'
import ConfigForm from './ConfigForm'
import Processing from './Processing'

import './AutoDesign.css'

const AutoDesign = ({ match }) => {
  return (
    <div className="AutoDesign">
      <header>
        <h1>Auto-design</h1>
        <p>
          Auto-design is an internal tool used to generate the map view of a
          fiber build for a client/substation/feeder combination. Auto-design
          generates geojson files containing the cable (lines), consumers
          (points), poles (points), and splicepoints (points), along with all
          the details required to complete the build, including the splice
          diagrams.
        </p>
      </header>

      <div className="content">
        <Switch>
          <Route
            path={`${match.path}/:substation/:feeder/config`}
            component={ConfigForm}
            exact
          />
          <Route
            path={`${match.path}/:substation/:feeder/process`}
            component={Processing}
            exact
          />
          <Route path="/:client/auto-design" component={SubstationFeeders} />
        </Switch>
      </div>
    </div>
  )
}

export default AutoDesign
