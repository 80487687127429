import React, { useState, useEffect } from 'react'
import { Route, NavLink } from 'react-router-dom'

import Substation from './Substation'
import Summary from './Summary'

import './Substations.css'

const Substations = ({ match }) => {
  const [stats, setStats] = useState(null)
  const [substations, setSubstations] = useState(null)

  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/test-coop/substations.json`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        return response.json()
      })
      .then(json => {
        setSubstations(json)
      })
  }, [])

  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/test-coop/stats.json`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        return response.json()
      })
      .then(json => {
        setStats(json)
      })
  }, [])

  if (stats === null || substations === null) return 'Loading'

  return (
    <div className="Substations">
      <nav>
        <ul>
          {substations.map((substation, i) => {
            return (
              <li key={i}>
                <NavLink
                  to={`${match.url}/${substation.id}`}
                  activeClassName="active"
                >
                  {substation.name}
                </NavLink>
              </li>
            )
          })}
        </ul>
      </nav>
      <Route path={`${match.path}/:substationId`} component={Substation} />
      <Route
        exact
        path={match.path}
        render={() => <Summary substations={substations} />}
      />
    </div>
  )
}

export default Substations
