import React from 'react'
import { withRouter } from 'react-router'

const Callback = props => {
  console.log('Callback')
  props.auth.handleAuthentication().then(() => {
    const profile = props.auth.getProfile()
    const isAdmin = profile['https://conexon.com/roles'].includes('admin')
    const client = profile['https://conexon.com/clients'].split(',')[0]

    if (isAdmin) {
      props.history.push('/clients')
    } else {
      props.history.push(`/${client}`)
    }
  })

  return <div>Loading user profile.</div>
}

export default withRouter(Callback)
