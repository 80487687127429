import React from 'react'

import './Feeder.css'

const Feeder = ({ match }) => {
  return (
    <div className="Feeder">
      <h3>Feeder {match.params.feederId}</h3>
    </div>
  )
}

export default Feeder
