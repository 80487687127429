import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'

const Processing = ({ match, history }) => {
  function useInterval(callback, delay) {
    const savedCallback = useRef()

    useEffect(() => {
      savedCallback.current = callback
    })

    useEffect(() => {
      function tick() {
        savedCallback.current()
      }
      if (delay !== null) {
        let id = setInterval(tick, delay)
        return () => clearInterval(id)
      }
    }, [delay])
  }

  const [message, setMessage] = useState('Starting')
  const [count, setCount] = useState(0)

  useInterval(
    () => {
      switch (count) {
        case 0:
          setMessage('Creating network')
          break
        case 1:
          setMessage('Creating taps and splices')
          break
        case 2:
          setMessage('Establishing address schema')
          break
        case 3:
          setMessage('Finding directions to source')
          break
        case 4:
          setMessage('Calculating light loss')
          break
        case 5:
          setMessage('Calculating cable')
          break
        case 6:
          setMessage('Calculating costs')
          break
        case 7:
          setMessage('Outputting data')
          break
        case 8:
          setMessage('Complete')
          break
        default:
          useState('Starting')
      }
      setCount(count => count + 1)
    },
    count === 9 ? null : 500
  )

  const { client, feeder } = match.params

  return (
    <section>
      <h2>
        Running for {client.toUpperCase()}{' '}
        {history.location.state.substationName} - {feeder}
      </h2>
      <h3>
        {count}. {message}
      </h3>
      {count === 9 ? (
        <React.Fragment>
          <Link to={`/${client}/map`}>View {client} map</Link>
        </React.Fragment>
      ) : null}
    </section>
  )
}

export default Processing
