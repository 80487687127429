import React from 'react'
import { NavLink, withRouter } from 'react-router-dom'

import './Status.css'

const Status = withRouter(({ history, auth }) => {
  const profile = auth.getProfile()
  let client
  if (profile) {
    client = profile['https://conexon.com/clients'].split(',')[0]
  }

  let name,
    isAdmin = null
  if (auth.isAuthenticated()) {
    name = profile.name
    isAdmin = profile['https://conexon.com/roles'].includes('admin')
  }

  return (
    <div className="Status">
      <div>
        <NavLink exact to="/" activeClassName="active">
          Home
        </NavLink>

        {auth.isAuthenticated() &&
        profile['https://conexon.com/roles'].includes('admin') ? (
          <NavLink to="/clients" activeClassName="active">
            Client List
          </NavLink>
        ) : null}

        {auth.isAuthenticated() &&
        !profile['https://conexon.com/roles'].includes('admin') ? (
          <NavLink to={`/${client}`} activeClassName="active">
            {client.toUpperCase()}
          </NavLink>
        ) : null}
      </div>

      <div className="accountActions">
        {auth.isAuthenticated() ? (
          <React.Fragment>
            <p>
              {profile ? `Logged in as ${name} ` : null}
              {isAdmin ? <strong>(Admin)</strong> : null}
            </p>

            <button
              className="logout"
              onClick={() => {
                auth.logout()
                history.push('/')
              }}
            >
              Logout
            </button>
          </React.Fragment>
        ) : (
          <button
            onClick={() => {
              auth.login()
            }}
          >
            Login
          </button>
        )}
      </div>
    </div>
  )
})

export default Status
