import React from 'react'

import './Stat.css'

const Stat = props => {
  if (props.category === 'Consumers') return null

  const { type, ytd } = props.stat
  const variance = (ytd.completed - ytd.plan).toFixed(2)
  const percentComplete = ((ytd.completed / ytd.plan) * 100).toFixed(2)

  return (
    <div className="Stat">
      <h1>{type}</h1>
      <div className="data">
        <h2>{ytd.completed} actual</h2>
        <h3>{ytd.plan} planned</h3>
        <h4
          className={
            variance === '0.00'
              ? 'equal'
              : variance > 0
              ? 'increase'
              : 'decrease'
          }
        >
          {variance} variance
        </h4>
        <br />
        <h4
          className={
            percentComplete === 'NaN'
              ? 'equal'
              : percentComplete > 100
              ? 'increase'
              : 'decrease'
          }
        >
          {percentComplete === 'NaN' ? 0 : percentComplete}% complete YTD
        </h4>
      </div>
    </div>
  )
}

export default Stat
