import React from 'react'

const SpeedTest = () => {
  return (
    <React.Fragment>
      <h1>SpeedTest</h1>
      <iframe
        title="Speed Test"
        width="100%"
        height="650px"
        frameBorder="0"
        src="https://conexon.speedtestcustom.com"
      />
    </React.Fragment>
  )
}

export default SpeedTest
