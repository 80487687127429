import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { getTubeColor } from '../utils/getTubeColor'
import { getStrandColor } from '../utils/getStrandColor'

import './SpliceDiagram.css'

const SpliceDiagram = ({ match }) => {
  const { client, substation, feeder, sequence } = match.params
  const modifiedSequence = sequence.includes('.')
    ? sequence.replace('.', '-')
    : `${sequence}-000`
  const filename = `s${substation}_f${feeder}_${modifiedSequence}`
  const [splice, setSplice] = useState(null)

  useEffect(() => {
    fetch(
      `${
        process.env.PUBLIC_URL
      }/${client}/splice/s${substation}/f${feeder}/${filename}.json`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }
    )
      .then(response => {
        return response.json()
      })
      .then(json => {
        setSplice(json)
      })
  }, [])

  if (splice === null) return 'Loading'

  return (
    <div className="diagram">
      <Link to={`/${match.params.client}/map`}>Back</Link>
      <header>
        <h1>{splice.coop}</h1>
        <h2>
          {splice.substation}:{splice.feeder}:{splice.sequence}:
          {splice.polenumber}
        </h2>
        <p>Substation:Feeder:Sequence:PoleNumber</p>
        <h3>Date: {splice.date}</h3>
        <h3>Work order: {splice.workorder}</h3>
        <h3>KM optics: {splice.kmOptics}</h3>
        <h3>Light level from design: {splice.designedLightLevel}</h3>
      </header>
      <div>
        <table>
          <thead>
            <tr>
              <th className="center" colSpan={4}>
                From
              </th>
              <th colSpan={2} />
              <th />
              <th colSpan={2} />
              <th className="center" colSpan={4}>
                To
              </th>
            </tr>
            <tr>
              <th className="center" width="8%">
                Seqence
              </th>
              <th width="7%">Equipment</th>
              <th width="12%">Cable</th>
              <th className="center" width="7%">
                Strand
              </th>
              <th className="center" width="2%" colSpan={2}>
                Input
              </th>
              <th className="center">Connection</th>
              <th className="center" width="2%" colSpan={2}>
                Output
              </th>
              <th className="center" width="7%">
                Strand
              </th>
              <th width="12%">Cable</th>
              <th width="7%">Equipment</th>
              <th className="center" width="8%">
                Seqence
              </th>
            </tr>
          </thead>
          <tbody>
            {splice.splice.map((cable, i) => {
              const connectedFrom = cable.connectedFrom
              const fromEquipment =
                connectedFrom.equipment.sequence === '' ? (
                  'NA'
                ) : (
                  <React.Fragment>
                    {connectedFrom.equipment.stationID === ''
                      ? 'NA'
                      : connectedFrom.equipment.stationID}
                  </React.Fragment>
                )

              const connectedTo = cable.connectedTo
              const toEquipment =
                connectedTo.equipment.sequence === '' ? (
                  'NA'
                ) : (
                  <React.Fragment>
                    {connectedTo.equipment.stationID === ''
                      ? 'NA'
                      : connectedTo.equipment.stationID}
                  </React.Fragment>
                )

              return (
                <tr key={i}>
                  <td>{connectedFrom.equipment.sequence}</td>
                  <td>{fromEquipment}</td>
                  <td>{connectedFrom.cable}</td>
                  <td className="center">{connectedFrom.strandNumber}</td>
                  <td
                    width="20px"
                    style={{
                      backgroundColor: getTubeColor(
                        connectedFrom.strandNumber,
                        connectedFrom.cableSize
                      )
                    }}
                  />
                  <td
                    width="20px"
                    style={{
                      backgroundColor: getStrandColor(
                        connectedFrom.strandNumber,
                        connectedFrom.cableSize
                      )
                    }}
                  />
                  <td className="center connection">{cable.connectionType}</td>
                  <td
                    width="20px"
                    style={{
                      backgroundColor: getStrandColor(
                        connectedTo.strandNumber,
                        connectedTo.cableSize
                      )
                    }}
                  />
                  <td
                    width="20px"
                    style={{
                      backgroundColor: getTubeColor(
                        connectedTo.strandNumber,
                        connectedTo.cableSize
                      )
                    }}
                  />
                  <td className="center">{connectedTo.strandNumber}</td>
                  <td>{connectedTo.cable}</td>
                  <td>{toEquipment}</td>
                  <td>{connectedTo.equipment.sequence}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default SpliceDiagram
