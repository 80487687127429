import React, { useState, useEffect } from 'react'

import MapProgress from '../common/MapProgress'
import Stats from '../common/Stats'

import './Dashboard.css'

const Dashboard = ({ match }) => {
  const [stats, setStats] = useState(null)

  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/${match.params.client}/stats.json`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        return response.json()
      })
      .then(json => {
        setStats(json)
      })
  }, [])

  if (stats === null) return 'Loading'

  return (
    <div className="Dashboard">
      <header>
        <h2>Phase 1 summary</h2>
        <h3>Week ending 3/22/2019</h3>
        <p>
          <em>
            See the <a href={`${match.url}/substations`}>substations</a> for
            more details.
          </em>
        </p>
      </header>
      {/*
          {stats.stats.map((stat, i) => {
            return stat.stats.map((type, i) => {
              return <Stat key={i} category={stat.category} stat={type} />
            })
          })}
        
 */}

      {stats.stats.map((stat, i) => {
        return (
          <Stats
            key={i}
            count={i}
            category={stat.category}
            stats={stat.stats}
          />
        )
      })}

      <section>
        <MapProgress client={match.params.client} stats={stats} />
      </section>
    </div>
  )
}

export default Dashboard
