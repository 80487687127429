import React, { useState, useEffect } from 'react'
import { Route, NavLink } from 'react-router-dom'

import Feeder from './feeders/Feeder'
import Summary from './feeders/Summary'

import './Substation.css'

const Substation = ({ match }) => {
  const [feeders, setFeeders] = useState(null)

  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/test-coop/feeders.json`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        return response.json()
      })
      .then(json => {
        setFeeders(json)
      })
  }, [])

  if (feeders === null) return 'Loading'
  return (
    <div className="Substation">
      <hr />
      <h2>Substation {match.params.substationId}</h2>

      <nav>
        <ul>
          {feeders.map((feeder, i) => {
            return (
              <li key={i}>
                <NavLink
                  exact
                  to={`${match.url}/feeder/${feeder.id}`}
                  activeClassName="active"
                >
                  {feeder.name}
                </NavLink>
              </li>
            )
          })}
        </ul>
      </nav>

      <Route path={`${match.path}/feeder/:feederId`} component={Feeder} />
      <Route
        exact
        path={match.path}
        render={() => <Summary feeders={feeders} />}
      />
    </div>
  )
}

export default Substation
