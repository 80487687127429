import React from 'react'
import { Switch, Route, Redirect, withRouter } from 'react-router-dom'

import Status from './Auth/Status'
import Home from './Home/Home'
import Callback from './Callback/Callback'
import Clients from './Clients/Clients'
import ClientList from './Clients/ClientList'
import SpeedTest from './SpeedTest/SpeedTest'
import SpliceDiagram from './SpliceDiagram/SpliceDiagram'

import './App.css'

import Auth from './Auth/Auth'
const auth = new Auth()

//TODO : need to use state to hold the isAuthenticated

// merges props to allow passing of additional props
const renderMergedProps = (component, ...rest) => {
  const finalProps = Object.assign({}, ...rest)
  return React.createElement(component, finalProps)
}

// custom route to pass props
// see https://github.com/ReactTraining/react-router/issues/4105#issuecomment-289195202
/*
const PropsRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        return renderMergedProps(Component, props, rest)
      }}
    />
  )
}
*/

// custom route to pass props AND require authentication
const ProtectedRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        return auth.isAuthenticated() === true ? (
          renderMergedProps(Component, props, rest)
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location, auth: props.auth }
            }}
          />
        )
      }}
    />
  )
}

const App = props => {
  console.log('App', localStorage.getItem('isLoggedIn'), auth.isAuthenticated())
  // if not logged out, try to renew
  if (localStorage.getItem('isLoggedIn') && !auth.isAuthenticated()) {
    auth.renewSessionPromise().then(() => {
      if (props.location && props.location.state) {
        props.history.push(props.location.state.from.pathname)
      }
    })
  }

  return (
    <div id="main">
      <Status auth={auth} />

      <Switch>
        <Route exact path="/callback" render={() => <Callback auth={auth} />} />
        <ProtectedRoute
          exact
          path="/splice-diagram/:client/:substation/:feeder/:sequence"
          component={SpliceDiagram}
        />
        <Route exact path="/" render={() => <Home />} />
        <ProtectedRoute exact path="/speed-test" component={SpeedTest} />
        <ProtectedRoute exact path="/clients" component={ClientList} />
        <Redirect strict exact from={'/:client/'} to={'/:client'} />
        <ProtectedRoute path="/:client" component={Clients} auth={auth} />
      </Switch>
    </div>
  )
}

export default withRouter(App)
